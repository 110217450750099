<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="应用ID" prop="id" v-show="false">
        <a-input v-model="form.id"/>
      </a-form-model-item>
      <a-form-model-item label="销售渠道">
      {{this.tenantName}}
      </a-form-model-item>
      <a-form-model-item label="等级名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称"/>
      </a-form-model-item>
      <a-form-model-item label="等级编码" prop="code">
        <a-input v-model="form.code" placeholder="请输入编码"/>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="memo">
        <a-textarea v-model="form.memo" placeholder="请输入备注">
        </a-textarea>
      </a-form-model-item>-->
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        id: undefined,
        name: '',
        code: '',
        defaultGrade: undefined,
        tenantName: '',
        tenantId: undefined,
        memo: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }],
        code: [{
          required: true,
          message: '编号不能为空',
          trigger: 'change'
        }]
      },
      tenantList: [],
      tenantId: undefined,
      tenantName: ''
    }
  },
  watch: {
    isShow (val) {

    }
  },
  created () {
    // this.getTenantId()
    this.tenantId = this.$cookies.get('tenantId')
    this.tenantName = this.$store.state.user.currentTenantName
  },
  computed: {},
  methods: {
    setForm (form = {}) {
      this.form = {
        id: form.id,
        name: form.name,
        code: form.code,
        tenantName: form.tenantName,
        tenantId: form.tenantId,
        memo: form.memo,
        defaultGrade: form.defaultGrade
      }
    },
    async getTenantId () {
      const res = await this.$store.dispatch('http', {
        api: 'tenantList'
      })
      if (res) {
        const that = this
        res.map(item => {
          that.tenantList.push({ id: item.id, name: item.name })
        })
      }
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        id: undefined,
        name: '',
        code: '',
        defaultGrade: undefined,
        tenantName: '',
        tenantId: undefined,
        memo: ''
      }
    },
    // 提交保存.
    async determine () {
      console.log('保存列表', JSON.stringify(this.form))
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'saveMemberGrade'
          if (this.form.id) {
            api = 'editMemberGrade'
            params.id = this.form.id
          } else {
            delete params.id
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '编辑' : '新增') + '成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
