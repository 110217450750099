<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button type="primary" @click="$refs.dialog.isShow = true">新建</a-button>
    </Breadcrumb>
    <a-table :columns="columns" :data-source="list" :rowKey='record=>record.id'
             :pagination="false"
             bordered>
             <span slot="action1" >
        {{this.tenantName}}
      </span>
       <span slot="actionEdit" slot-scope="scope">
        <a @click="$refs.dialog.setForm(scope);$refs.dialog.isShow = true">编辑</a>
      </span>
      <span slot="actionDelete" slot-scope="scope">
                  <a-popconfirm
                    title="确定删除吗？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirm(scope.id)"
                    @cancel="cancel"
                  >
            <a href="#">移除</a>
          </a-popconfirm>
      </span>
    </a-table>
    <br/>
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
    <save-member ref="dialog" @save="queryData"></save-member>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import SaveMember from './addOrEdit.vue'

export default {
  components: {
    Breadcrumb,
    SaveMember
  },
  data () {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      pageSize: 10,
      current: 1,
      tenantId: undefined,
      tenantName: '',
      breadList: [
        {
          name: '渠道会员等级',
          url: ''
        }
      ],
      isQueryLoading: false,
      list: [],
      columns: [
        {
          title: '销售渠道',
          dataIndex: '',
          key: '',
          width: 125,
          scopedSlots: { customRender: 'action1' }
        },
        {
          title: '等级名称',
          dataIndex: 'name',
          key: 'name',
          width: 125
        },
        {
          title: '等级编码',
          dataIndex: 'code',
          key: 'code',
          width: 125
        },
        {
          title: '备注',
          dataIndex: 'memo',
          key: 'memo',
          width: 80
        },
        {
          title: '时间',
          dataIndex: 'gmtModified',
          key: 'gmtModified',
          width: 125
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'actionEdit' },
          key: 'operation1',
          ellipsis: true,
          width: 125
        },
        {
          title: '高级操作',
          scopedSlots: { customRender: 'actionDelete' },
          key: 'operation2',
          ellipsis: true,
          width: 125
        }
      ]
    }
  },
  created () {
    this.queryData()
    this.tenantName = this.$store.state.user.currentTenantName
  },
  methods: {
    // 是否确认弹窗口  方法
    confirm (scope) {
      this.del(scope)
    },
    cancel (values) {
      console.log(values)
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(event, pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.queryData()
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'memberGradeList',
        params: {
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      this.list = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    async del (id) {
      const res = await this.$store.dispatch('http', {
        api: 'deleteMemberGrade',
        query: {
          id: id
        }
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '删除成功'
        })
        this.queryData() // 传入数字 重置列表不会更改当前页
        // this.reloadList() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
